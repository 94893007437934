<template>
  <v-container class="pa-0">
    <default-modal
      v-if="showModal"
      :message="modalState.message"
      :action="modalState.action"
      :cancel-message="modalState.cancelMessage"
      @close="closeModal"
      @action="doAction"
    />
    <task-option-modal
      v-if="showTaskOptionModal"
      option="task"
      :defaultNumber="defaultNumber"
      @close="closeOptionModal"
      @action="addWorkToJob"
    />
    <v-row
      class="header"
      justify="space-around"
    >
      <img
        src="@/assets/back.png"
        alt="뒤로가기"
        type="button"
        @click="$router.replace('/task')"
      >
      <span class="span-back"> &nbsp;&nbsp;뒤로 </span>
      <v-spacer />
      <v-col
        class="header-img ma-0 pa-0"
        align-self="center"
        cols="auto"
      >
        <img
          alt="추가"
          src="@/assets/add.png"
          type="button"
          @click="$router.replace('/task/plan')"
        >
      </v-col>
      <span class="span-task-add">&nbsp;&nbsp;플랜 생성&nbsp;</span>
    </v-row>
    <v-row class="content-container mt-10">
      <v-col>
        <v-row>
          <v-col cols="12">
            <h1 class="title-task">
              생성된 태스크 플랜
            </h1>
          </v-col>
          <v-col
            v-for="(item, i) in workAllList"
            :key="i"
            class="btn-container mt-4"
            cols="12"
          >
            <v-card
              class="task"
              color="#d4d4d4"
            >
              <v-row justify="space-between">
                <v-col cols="3">
                  <span class="ml-5">{{ item.work_name }}</span>
                </v-col>
                <v-col cols="3">
                  <v-btn
                    class="btn-task"
                    height="70px"
                    color="#00BFB4"
                    dark
                    @click="showAddJobModal(i)"
                  >
                    태스크 추가
                  </v-btn>
                </v-col>
                <v-col cols="3">
                  <v-btn
                    class="btn-task"
                    height="70px"
                    color="#7c7c7c"
                    dark
                    @click="showDeleteTaskModal(i)"
                  >
                    삭제
                  </v-btn>
                </v-col>
                <v-col cols="3">
                  <v-btn
                    class="btn-task"
                    height="70px"
                    color="#7c7c7c"
                    dark
                    @click="workEdit(i)"
                  >
                    수정
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DefaultModal from '@/components/modals/defaultModal.vue';
import TaskOptionModal from '@/components/modals/task/taskOptionModal.vue';
import EventBus from '@/main.js';

export default {
  name: 'Work',
  components: {
    DefaultModal,
    TaskOptionModal
  },
  props: {
    locations: {
      type: Array,
      default: null
    },
    mapInfo: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      showModal: false,
      showTaskOptionModal: false,
      modalState: {
        message: null,
        action: null,
        cancelMessage: null,
      },
      workAllList: [],
      workId: null,
      work: null,
      defaultNumber: null,
      modalConfigSelected: null,
      modalConfigNumber: null,
    }
  },
  created() {
    EventBus.$off('taskConfig');
    EventBus.$on('taskConfig', (configs) => {
      this.modalConfigSelected = configs.selected;
      this.modalConfigNumber = configs.inputNumber;
    });
    this.initialize();
  },
  methods: {
    initialize() {
      return this.fetchWorkAllList().catch(err => { throw Error(err) });
    },
    fetchWorkAllList() {
      return this.$axios.get('/work/allList')
        .then(res => {
          if (res.status === 200) {
            this.workAllList = res.data;
          }
        });
    },
    doAction() {
      if (this.modalState.action === '삭제') {
        this.deleteWork()
          .then(this.fetchWorkAllList)
          .catch(err => { throw Error(err) });
      }
      this.closeModal();
    },
    closeModal() {
      this.modalState.message = null;
      this.modalState.action = null;
      this.modalState.cancelMessage = null;
      this.showModal = false;
    },
    addWorkToJob() {
      if (Boolean(this.modalConfigSelected) && Boolean(this.modalConfigNumber)) {
        const selected = this.modalConfigSelected;
        const number = this.modalConfigNumber;

        if (number > 0) {
          this.closeOptionModal();

          localStorage.setItem(`job_${this.work.map_id}_${this.work.work_id}`, number);

          return this.$axios.post('/job', {
            map_id: this.work.map_id,
            work_id: this.work.work_id,
            job_state: "STBY",
            job_repeat: parseInt(number),
            job_start_count: 0,
            job_done_count: 0,
            job_fail_count: 0,
            job_order: selected
          })
            .then(() => {
              EventBus.$emit('socketEmit', 'changeJob');
            })
            .catch(err => { throw Error(err); });
        } else {
          this.$toast('0보다 큰 수를 입력해 주세요', {
            position: 'top-center'
          });
        }

      } else {
        this.$toast('우선순위와 횟수를 모두 입력해 주세요', {
          position: 'top-center'
        });
      }
    },
    closeOptionModal() {
      this.showTaskOptionModal = false;
      this.modalConfigSelected = null;
      this.modalConfigNumber = null;
    },
    showDeleteTaskModal(index) {
      this.workId = this.workAllList[index].work_id;
      this.modalState.message = '해당 플랜을 삭제하시겠습니까?';
      this.modalState.action = '삭제';
      this.showModal = true;
    },
    deleteWork() {
      return this.$axios.delete(`/work/${this.workId}`, {
        workId: this.workId
      });
    },
    workEdit(index) {
      const workId = this.workAllList[index].work_id;

      return this.$axios.get(`/work/list/${workId}`)
        .then(res => {
          if (res.status === 200) {
            return this.$router.replace({
              name: 'Plan',
              params: {
                workData: res.data,
              }
            });
          }
        })
        .catch(err => { throw Error(err) });
    },
    showAddJobModal(index) {
      this.work = this.workAllList[index];
      this.defaultNumber = localStorage.getItem(`job_${this.work.map_id}_${this.work.work_id}`);
      this.showTaskOptionModal = true;
    }
  }
}
</script>

<style lang="scss" scoped>
.span-back {
  color: #ffffff;
  font-size: 80px !important;
}

.span-task-add  {
  justify-self: right;
  color: #ffffff;
  font-size: 80px !important;
}

.content-container {
  height: 80vh;
  overflow: auto;
}

.title-task {
  color: #ffffff;
  font-size: 50px;
}

.task {
  border-radius: 15px !important;
  vertical-align: middle;
  font-size: 40px;
  color: #7c7c7c !important;
}

.btn-task {
  width: 80%;
  border-radius: 10px;
  font-size: 30px !important;
  font-weight: bold !important;
}

@media (max-width: 1700px) {
  .header img {
    width: 50px;
    height: 50px;
  }

  .span-back {
    font-size: 40px !important;
  }

  .span-task-add {
    font-size: 40px !important;
  }

  .task {
    height: 50px;
    font-size: 30px;
  }

  .task > .row > .col {
    padding: 0 !important;
  }

  .btn-task {
    height: 40px !important;
    font-size: 20px !important;
  }
}

@media (max-width: 1140px) {
  .header img {
    width: 30px;
    height: 30px;
  }

  .span-back {
    font-size: 20px !important;
  }

  .span-task-add {
    font-size: 20px !important;
  }

  .title-task {
    font-size: 30px;
  }

  .task {
    height: 35px;
    font-size: 20px;
  }

  .btn-task {
    height: 25px !important;
    font-size: 11px !important;
  }
}
</style>
